import {NgModule} from '@angular/core'
import {GridComponent} from './grid/grid.component'
import {GridItemDirective} from './grid/grid-item.directive'
import {AsyncPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common'
import {SliderComponent} from './slider/slider.component'
import {SliderItemDirective} from './slider/slider-item.directive'
import {BulletIndicatorComponent} from './bullet-indicator/bullet-indicator.component'
import {CarouselComponent} from './carousel/carousel.component'
import {CarouselSlideDirective} from './carousel/carousel-slide.directive'
import {ListPaginationComponent} from './pagination/list-pagination.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {ButtonModule} from '../button/button.module'
import {ChipsComponent} from './chips/chips.component'
import {ListComponent} from './list.component'
import {ListItemComponent} from './list-item.component'
import {DynamicIconModule} from '../dynamic-icon/dynamic-icon.module'
import {I18nDirective} from '../i18n/i18n.directive'
import {TranslocoDirective} from '@ngneat/transloco'

@NgModule({
  declarations: [
    GridComponent,
    GridItemDirective,
    SliderComponent,
    SliderItemDirective,
    CarouselComponent,
    CarouselSlideDirective,
    ListPaginationComponent,
    BulletIndicatorComponent,
    ChipsComponent,
    ListComponent,
    ListItemComponent,
  ],
  imports: [
    NgClass,
    NgTemplateOutlet,
    NgForOf,
    NgIf,
    FontAwesomeModule,
    ButtonModule,
    AsyncPipe,
    DynamicIconModule,
    I18nDirective,
    TranslocoDirective,
  ],
  exports: [
    GridComponent,
    GridItemDirective,
    SliderComponent,
    SliderItemDirective,
    CarouselComponent,
    CarouselSlideDirective,
    ListPaginationComponent,
    BulletIndicatorComponent,
    ChipsComponent,
    ListComponent,
  ],
})
export class ListModule {}
